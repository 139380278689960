

































import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import store from '@/store'
import router from '@/router'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'
import LoadingOverlay from '@/components/common/LoadingOverlay.vue'

@Component({
  components: {
    LoadingOverlay,
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})
export default class LogIn extends Vue {
  private value = ''
  private loginButtonDisabled = true;

  private loadingAnimationVisible = true

  private unwatchFns: Array<()=>void> = [];

  private authorizationOutcome: AuthorizationOutcome | null = null

  get codeIsInvalid (): boolean {
    return this.authorizationOutcome != null && this.authorizationOutcome !== AuthorizationOutcome.valid
  }

  get code (): string {
    return this.value
  }

  showLoadingAnimation (): void {
    this.loadingAnimationVisible = true
  }

  hideLoadingAnimation (): void {
    setTimeout(() => { this.loadingAnimationVisible = false }, 600)
  }

  created (): void {
    sessionStorage.clear()
    this.$root.$emit('clear-timer')
    this.unwatchFns.push(
      store.watch(
        (store, getters) => getters['auth/loginButtonIsEnabled'],
        (loginButtonEnabled) => {
          this.loginButtonDisabled = !loginButtonEnabled
        },
        { immediate: true }
      )
    )

    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.$router.push('/')
      })
    }

    this.unwatchFns.push(
      store.watch(
        (store, getters) => getters['auth/authorizationOutcome'],
        (authorizationOutcome) => { this.authorizationOutcome = authorizationOutcome }
      )
    )
  }

  mounted (): void {
    setTimeout(this.setFocusToInput)
    const orgCodeFromQuerystring = this.$route.query.orgcode as string

    if (orgCodeFromQuerystring) {
      this.value = orgCodeFromQuerystring
      this
        .updateCode()
        .then(() => this.showLoadingAnimation())
        .then(() => {
          return store
            .dispatch('auth/authorize')
            .then(this.handleAuthorizationResponse)
        })
        .finally(() => this.hideLoadingAnimation())
    } else {
      this.loadingAnimationVisible = false
    }
  }

  beforeDestroy (): void {
    this.unwatchFns.forEach(unwatchFn => unwatchFn)
  }

  private updateCode (): Promise<void> {
    return store.dispatch('auth/setUpdatedOrganizationCode', this.code)
  }

  private setFocusToInput (): void {
    const inputFields = document.querySelectorAll('.input-field')
    const el = inputFields[0] as HTMLInputElement
    el.focus()
    setTimeout(() => el.select())
  }

  private submit (): void {
    if (this.loginButtonDisabled) {
      return
    }

    store
      .dispatch('auth/authorize')
      .then(this.handleAuthorizationResponse)
  }

  private handleAuthorizationResponse (): void {
    const authorizationOutcome = store.getters['auth/authorizationOutcome']
    if (authorizationOutcome !== AuthorizationOutcome.valid) {
      this.setFocusToInput()
      return
    }

    router.push({ name: 'welcome' })
  }
}
