










import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'

import lottie from 'vue-lottie'

import animation from '@/assets/animated-heart.json'

@Component({
  components: {
    lottie
  }
})
export default class LoadingOverlay extends Vue {
  @Prop({ type: Boolean, required: true })
  loading!: boolean

  lottieOptions = {
    animationData: animation
  }
}
